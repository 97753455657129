@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
        
*{
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-decoration: none !important;
}
.Content{
  display: flex;
}
.buttomContaint{
  background-color:#EFF3FD;
  padding: 20px;
  min-height: 90vh;
}

.star-Mandatory-field{
  vertical-align: super;
  color: red;
  font-size: large;
}

.containt{
  width:100%;
  flex: 4;
}
.navBar{
  align-items: center;
}


.layout{
  background-color: aliceblue;
  width: 100%;
}



.icon-suffix:hover{
  color:#00ff80 ;
  
}
.logo1{
  width: 8rem;
  height: 4rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
.logo2{
  width: 8rem;
  height: 4rem;
  margin-bottom: 2rem;
  margin-left: 2rem;



}
.navbar-collapse{
  display: flex ;
  justify-content: flex-end;
  margin-right: 2rem;

}
.navbar-toggler{
  margin-left: 20rem;
}
.navbar{
  display: flex ;
  justify-content: flex-end;
  margin-right: 2rem;
  width:85%;
  margin-left: 15rem;

}

.sidebar{
  height: 100vh;
}
.btn-outline-primary {
margin-left: 15rem;
}
#card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  border: none;
  

}
.spanCard{
  margin-left: 0.4rem;
}

.notification-count {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 12px;
}

.bell-icon {
  cursor: pointer;
  font-size: 30px;
}

.rnc__notification-item--info  {
  background-color:  #ff4444f0 !important; /* Custom background color for 'success' type */
  border-left: none !important;
}
.rnc__notification-item--success    {
  background-color: #00c496 !important; /* Custom background color for 'success' type */

  border-left: none !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding:0 1rem
}