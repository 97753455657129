.tâches-tech{
    display: flex;
    width: 100%; 
    gap : 15px;
}
.search{
    width: 50%;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin-left: 50px;
    box-sizing: border-box;
}

.bar-table{
    display: flex;
    box-sizing: border-box;
    justify-content: stretch;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 10vh;
    padding: 8px;
}

.left-bar-table{
    display: flex;
    width: 45%;
    align-items: self-end;
    gap: 20%;
}
.right-bar-table{
    display: flex;
    justify-content: end;
    width: 55%;
}
.react-datalist-input__textbox {
    border: none;
}
.Table-tech-commerciale{
    background-color: white;
    padding: 2rem 1rem 2rem 2rem;
    margin-top: 1rem;
    border-radius: 1rem;
    width: 100%;
}
.mb-3{
    margin-top: 20px;
}
.imageSlider{
    width: 100%;
    display: flex;
    justify-content: center;
}
.vl {
    border-left: 1px solid gray;
    height: 7px;
  }

.tâches-statut{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius:10px;
    font-weight: 500;
    background-color: #ececee;
}
    
.tâches-statut:hover{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius:10px;
    font-weight: 500;
    background-color: #ececee;
}
    
.Filter h5 span{
    margin-top: 100px;
}
.filter-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    width: 100%;
    gap: 20px;
}
.filter-button button{
    width: 100%;
}
.data-to-complete{
    display: flex;
}

.info{
    font-weight: 600;
    font-style: normal;
    min-width: 125px;
    line-height: 10px;
}
.ul li{
    line-height: 40px;
}

@media only screen and (max-width: 1250px) {
    .tâches-tech{
        display: grid;
        grid-template-columns: auto; 
    }
  
 
}
@media only screen and (max-width: 800px) {
    .mobileversion{
        display: none;
    }
    .tâches-tech{
        display: grid;
        grid-template-columns: auto; 
        
    }
    .bar-table {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px -8px 10px 0px;
    }
    .search{
        width: 100%;
        margin-left: 0;
    }
  
}
