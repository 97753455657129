.sideBarContainer{
    margin-right: 25px;    
    max-height: 95vh;
    position: sticky;
    top: 0;
}
.hswPQH{
    width: 113px !important;
    min-width: 185px !important;
}

.fZichJ .side-icon {
    margin-right: 6px;
    width: 22px;
}

.dropdown-toggle::after{
content: "";
display: none;
display: list-item;
 
}
.hswPQH.toggled{
    width: 80px !important;
    min-width: 80px !important;
}

.cQiZws{
    position: fixed !important;
}

.fZichJ.toggled{
    display: flex;
    justify-content: start !important;
    /* height: 43px !important; */
}



.bPQHAq .head-div{
    display: flex;
    justify-content: start !important;
    padding: 24px 38px !important;
}

.gEYqUT{
    margin: 10px 15px !important;
}

.XiG {
    position: relative
}

.Hsu {
    box-sizing: border-box;
}

.oy8 {
    display: block;
}

.XiG {
    position: relative;
}

.INd {
    border-radius: 50%;
}

.BG7 {
    cursor: pointer;
}

.L4E {
    width: 100%;
    width: calc(12/var(--g-ncols)*100%);
}

.S9z {
    transition: transform 85ms ease-out;
}

.mQ8 {
    justify-content: center;
}

.KS5 {
    align-items: center;
}

.Jea {
    display: flex;
    flex-direction: row;
}

.VxL {
    margin-bottom: 8px;
}

.ho- {
    border-radius: 16px;
}

.ojN {
    bottom: 0;
    bottom: var(--space-0);
}

.p6V {
    right: 0;
    right: var(--space-0);
}

._he {
    position: absolute;
}

.urM {
    background-color: #fff;
}

.jzS {
    flex-direction: column;
}

.C9i, .C9i>.xuA {
    margin-left: 0;
    margin-right: 0;
}
.TB_, .TB_>.xuA {
    margin-bottom: 0;
    margin-top: 0;
}
.un8 {
    display: flex;
}

.noti_container{
    padding: 25px 0px 4px;
}
.noti_container h1{
    font-size: 30px;
    font-weight: 600;
}

.scrollableList{
    padding: 3px 5px;
    text-align: start;
}

.Ll2{
    color: black;   
}

