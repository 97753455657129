.calendar{
    background-color: white;
    padding: 60px;
    border-radius: 20px;
}
.fc .fc-col-header-cell-cushion {
    color: #16294D;
    font-weight: bold;
    text-decoration: none;
    font-size: larger;
}
.fc-daygrid-day-number{
    color: #16294D;
    font-weight: 600;
    text-decoration: none;
    font-size: larger;
}

.fc .fc-button-primary:disabled {
    background-color: #16294D;
    opacity: 100%;

}
.fc-event-time{
    display: none;
}
.fc-event {
    color: white !important; 
    background-color: #16294D;
}
.fc-event:hover {
    color: black !important; 
    background-color: #00DEA3;
    text-decoration:  none;
}
