.Table-tasks{
    background-color: white;
    padding: 2rem;
    margin-top: 1rem;
    border-radius: 1rem;
}

.filter-task-technicien{
    border-radius: 1rem;
    min-height: 10vh;
    width: 50%;
}

.top-bar-table-task-technicien{
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: end;
    padding: 0px 50px 10px 10px;
}

.filter-button-task-technicien{
    display: flex;
    justify-content: start;
    gap: 10px;
    align-items:center
}