

/* 
.inputSearch{
    display: flex;
    justify-content: flex-end;
} */
.icon-input{
    position: relative;
    display: inline-block;
}
.icon-input > i:first-of-type{
    left: 0.75rem;
    color: #6c757d;
}
.icon-input > i{
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
}
.icon-input > .input-text{
    padding-left: 2.5rem
}
.input-text{
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 6px;
}
.searchTasks{
    background: #f8f9fa;
    color: #343a40;
    border: 1px solid #dee2e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 700;
}

.table > thead > tr > th{
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dee2e6;
    border-width: 0 0 1px 0;
    font-weight: 700;
    color: #343a40;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}