.navBar{
    height: 13vh;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    display: flex;
    padding: 8px;
}

.bell-icon{
    font-size: 30px;
}
.ContainerTitle{
    max-width: 500px;
}
.fa-user{
    font-size: 20px;
}

.profilContainer{
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 150px;
    
    
}
.profil{
    border: none;
    text-align: center;
    text-align: center;
    align-items: center;
    width: 50px;
    height: 45px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 10px 20px rgba(39, 39, 39, 0.25);
}

.notificationNumber{
    color: white;
    position: absolute;
    top: 3px;
    right: 1px;
    background-color: #00c496ff;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    text-align: center;
    z-index: 1;
    align-items: center;
    justify-content: center;
}

#messagesList{
    padding-bottom: 30px;
}

.messageStatu{
    background-color: aquamarine;
    font-size: bold;
}