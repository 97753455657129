 .confirm-popup {
        
        position: absolute !important;
        margin-top: -100px;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        padding: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }


  .message {
    margin-bottom: 10px;
  }
  
  .confirm-btn {
    background-color: #4caf50;
    color: #fff;
    padding: 8px 16px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }
  
  .cancel-btn {
    background-color: #f44336;
    color: #fff;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
  }
  