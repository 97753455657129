/* card */
:root {
    --blue: #16294D;
    --green: #00DEA3;
  }
  .chart-dash{
    margin-top: 20px;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

.chart-title{
  margin-left: 20px;
}

/* all buttons  */
.btn-primary{
    --bs-btn-color: white;
    --bs-btn-bg: #06c491;
    --bs-btn-border-color: #00DEA3;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #16294D;
    --bs-btn-hover-border-color: #16294D;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #16294D;
    --bs-btn-active-border-color: #16294D;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #16294D;
    --bs-btn-disabled-border-color: #16294D;
}


.chart{
  margin-top: 30px;
  background: white;
  padding: 20px 20px 10px 10px;
  border-radius: 30px;
  
}
.dashcontainer{
    display: flex;
    justify-content: end;
    width: 100%;
    padding-right: 20px;
}

.cardRow{
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 100%;
}
  .cardStats{
    padding: 0.4rem 1rem;
    
  }
.cardStats .card-body{
    text-align: center;
}
.cardStats .card-title{
    color: var(--blue);
}
.cardStats .card-text{
    color: var(--green);
    font-weight: bolder;
    font-size: larger;
}

h4{
  padding-left: 25px;
}
.btn-validate{
  display: flex;
  gap: 10px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
    .cardRow {
        grid-template-columns: auto  auto;

    }
    .cardStats{
      margin-top: 10px;
    }
    .chart-dash{
      grid-template-columns: auto;
      justify-content: center;
    }

    .tâches-dash{
      display: none;
    }
   
  }

@media only screen and (max-width: 500px) {
    .cardRow {
        grid-template-columns: auto;

    }
    .cardStats{
      margin-top: 10px;
    }
    .chart-dash{
      grid-template-columns: auto;
      justify-content: center;
    }
    .form-select{
      margin-top: 10px;
    }
    .btn-validate{
      margin-top: 10px;
      text-align: center;
    }
    .tâches-dash{
      display: none;
    }

  }