/* card */
:root {
    --blue: #16294D;
    --green: #00DEA3;
  }

  .Careds {
    padding: 10px;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
  
  }
.pointer{
  cursor:pointer;
}

.Search-input{
  display: block;
  padding: 0.75rem 1.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.cardStats{
    padding: 0.4rem 1rem;
}
.cardStats .card-body{
    text-align: center;
}
.cardStats .card-title{
    color: var(--blue);
}
.cardStats .card-text{
    color: var(--green);
    font-weight: bolder;
    font-size: xx-large;
}
.tablesApp{
    width:100% ;
    padding:2rem;
    margin-top:1rem;
    border-radius:2rem;
}


@media only screen and (max-width: 1024px) {
    .cardRow {
        grid-template-columns: auto  auto;

    }
    .AppCard {
      display: grid;
      grid-template-columns: auto;
    }
    .tablesApp{
      padding:1rem;
      margin-top:1rem;
      border-radius:2rem;
      margin-left: 0px;
  }
  }
  
@media only screen and (max-width: 900px) {

  .Careds {
    padding: 10px;
    display: grid;
    grid-template-columns: auto;
    width: 100%;
  
  }
  .tablesApp{
    padding:1rem;
    margin-top:1rem;
    border-radius:2rem;
    margin-left: 0px;
}}
