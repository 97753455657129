/* card */
:root {
    --blue: #16294D;
    --green: #00DEA3;
  }
.AppCard{
    padding: 10px;
    display: grid;
    grid-template-columns: auto auto auto ;
    width: 100%;
}
.pointer{
  cursor:pointer;
}

.IMEI-input{
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

  .cardStats{
    padding: 0.4rem 1rem;
  }
.cardStats .card-body{
    text-align: center;
}
.cardStats .card-title{
    color: var(--blue);
}
.cardStats .card-text{
    color: var(--green);
    font-weight: bolder;
    font-size: x-large;
}

@media only screen and (max-width: 1024px) {
    .cardRow {
        grid-template-columns: auto  auto;

    }
  }
@media only screen and (max-width: 500px) {
    .cardRow {
        grid-template-columns: auto;

    }
  }