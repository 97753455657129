.button-end{
    display: flex;
    justify-content: end;
    padding: 20px;
    
}

.permission li{
    list-style-type: decimal-leading-zero;

}
li{
    list-style-type: none;
    line-height: 32px;
}
.data-user{
    display: flex;
}
.usercards{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap:25px;
    
}
.usercard{
    border: none;
    width: 48%;
}

.card-body{
    padding: 15px 10px 15px 10px;
}

.data{
    font-weight: 600;
    font-style: italic;
    width: 40%;

}

.userimage{
    padding: 1px 3px 8px 18px;
    
    margin-top: 36px;

}

.headercard{
    background-color: #fff;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 0 36px;
    align-items: center;
}

.card-info{
    display: flex;
    align-items: center;
    gap: 20px;
}    
.cardTextLeft{
    margin-bottom: 0px !important;
    width: 138px;
    font-weight: 700;
}
.cardTextInfo{
    max-width: 349px;
}
@media only screen and (max-width: 1250px) {

    .usercard{
        border: none;
        width: 80%;
    }
    .headercard{
        font-size: 1rem;
    }
    .card-body{
        padding: 10px 10px 20px 10px;
        display: block;
    }
    .card-info{
        
    }
}