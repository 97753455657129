.Table-tasks{
    background-color: white;
    padding: 2rem 1rem 2rem 1rem;
    margin-top: 1rem;
    border-radius: 1rem;
}

.filter-task-technicien{
    width: 50%;
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: end;
}

.bar-table-task-technicien{
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 50px 10px 10px;
}

.filter-button-task-technicien{
    display: flex;
    justify-content: start;
    gap: 10px;
    align-items:center
}