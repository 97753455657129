.show-image {
  align-items: center;
  background-color: rgba(12, 13, 13, 0.93);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all 0.25s ease-in;
  width: 100%;
  z-index: 5000;
}

.close-retation-buttons {
  display: flex;
  justify-content: center;
  fill: #fff;
  cursor: pointer;
  height: 46px;
  position: fixed;
  right: 10px;
  top: 5px;
  z-index: 101;
  gap: 8px;
  width: 100px;
  height: 50px;
}

.close-retation-button-show-image {
  fill: #fff;
  cursor: pointer;
  height: 46px;
  width: 46px;
  z-index: 101;
}
