.tâches-tech-container {
  display: flex;
  /* grid-template-columns: 2fr 1fr; */
  /* gap : 30px; */
}

.Table-tech {
  width: 100%;
  background-color: white;
  padding: 2rem 1rem 2rem 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
  /* text-align: center; */
}

.tâches-statut {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  background-color: #ececee;
}

.tâches-statut option {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-weight: 500;
  background-color: white;
}

.plus-data {
  background-color: white;
  padding: 2rem 1rem 1rem 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}
.data-to-complete {
  display: flex;
}

.info {
  font-weight: bolder;
  font-style: normal;
  min-width: 170px;
  line-height: 10px;
  margin-right: 2 0px;
}
.ul li {
  line-height: 40px;
  max-width: 260px;
}

.listAccessoires {
  display: inline-flex;
  gap: 15px;
}

p {
  margin-bottom: 0rem;
}
.allContent {
  padding: 20px 50px 30px;
}

.search-appareil-technicien {
  width: 33.5%;
  display: flex;
  margin: 0px 0px 15px 0px;
}

@media only screen and (max-width: 1800px) {
  .tâches-tech {
    display: grid;
    grid-template-columns: auto;
  }
  .allContent {
    padding: 10px;
  }
}
@media only screen and (max-width: 1000px) {
  .versionmobile {
    display: none;
  }
  .tâches-tech {
    display: grid;
    grid-template-columns: auto;
  }
  .bar-table {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px -8px 10px 0px;
  }
  .search {
    width: 100%;
    margin-left: 0;
  }
  .allpage {
    padding: 1rem !important;
  }
  .plus-data {
    font-size: 15px;
  }
  .data-to-complete {
    font: bold;
  }
  .ul {
    padding-left: 0rem;
  }
  .allContent {
    padding: 10px;
  }
}

@media only screen and (max-width: 1120px) {
  .bar-table {
    gap: 20px;
  }

  .tâches-tech-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .hswPQH.toggled {
    width: 80px !important;
    min-width: 80px !important;
  }
}
